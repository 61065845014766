// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'https://bmorebunks.com',
//   // baseUrl: 'http://192.168.18.67:3006',
//   // baseUrl: 'http://385f8113.ngrok.io',
//   encryptionKey: '1hCfgh559IEA3pku',
//   hostelId: 'SRLZwkLw',
//   serwrkr: './assets/js/firebase-messaging-sw.js',
//   firebase: {
//     apiKey: 'AIzaSyAqMd7LZUVOjsk7ur9fhr2o2870Y_a-fGc',
//     authDomain: 'cloudbunks.firebaseapp.com',
//     databaseURL: 'https://cloudbunks.firebaseio.com',
//     projectId: 'cloudbunks',
//     storageBucket: 'cloudbunks.appspot.com',
//     messagingSenderId: '305028216401',
//     appId: '1:305028216401:web:5949a6f72ad5e6e2d6ea70',
//     measurementId: 'G-V4SR16HFHZ'
//   }
// };

export const environment = {
  production: false,
  baseUrl: 'https://dev.cloudbunks.com',
  // baseUrl: 'http://192.168.18.67:3006',
  // baseUrl: 'http://385f8113.ngrok.io',
  encryptionKey: '1hCfgh559IEA3pku',
  hostelId: 'SRLZwkLw',
  serwrkr: './assets/js/firebase-messaging-sw.js',
  firebase: {
    apiKey: 'AIzaSyAqMd7LZUVOjsk7ur9fhr2o2870Y_a-fGc',
    authDomain: 'cloudbunks.firebaseapp.com',
    databaseURL: 'https://cloudbunks.firebaseio.com',
    projectId: 'cloudbunks',
    storageBucket: 'cloudbunks.appspot.com',
    messagingSenderId: '305028216401',
    appId: '1:305028216401:web:5949a6f72ad5e6e2d6ea70',
    measurementId: 'G-V4SR16HFHZ'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented  in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
// import 'zone.js/dist/zone-error';  // Included with Angular CLI. */
