import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from '../environments/environment';
import { DataService } from './services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Bmorebunks Hostel in Baltimore Near Hopkins University – Bmorebunks Hostel';
  public deviceInfo = null;
  public isMobile = false;
  public isTablet = false;
  public isDesktop = false;
  constructor(private dataService: DataService, private route: Router, private activeRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService, private titleService: Title, private metaService: Meta,
  ) {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Hostel in Baltimore' },
      {
        name: 'description', content: `Bmorebunks Hostel in Baltimore is the only hostel in Baltimore. It is situated close to everything with cozy and spacious rooms to rent for travelers and students at best rates.` },
      { name: 'author', content: 'Becky' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: this.title },
      {
        property: 'og:description', content: `Cloudbunks is the only hostel in Baltimore.
      It is situated close to everything with cozy and spacious rooms to rent for travelers and students at best rates.`},
      { property: 'og:url', content: 'http://cloudbunks.com/' },
      { property: 'og:site_name', content: this.title }
    ]);
    this.detectDevice();
    const config = environment.firebase;
    firebase.initializeApp(config);
    navigator.serviceWorker
      .register(environment.serwrkr)
      .then((registration) => {
        firebase.messaging().useServiceWorker(registration);
        const messaging = firebase.messaging();
        messaging.requestPermission().then(function () {
          // TODO(developer): Retrieve an Instance ID token for use with FCM.
          messaging.getToken().then(function (currentToken) {
            if (currentToken) {
              dataService.setFcmToken('' + currentToken);
               dataService.getFcmToken();
            } else {
              // Show permission request.
              // Show permission UI.
            }
          }).catch(function (err) {
          });
          // ...
        }).catch(function (err) {
        });
        messaging.onMessage(function (payload) {
          window.alert('alert ' + payload.notification.body);
        });
      })
      .catch(err => {
      });
  }
  ngOnInit() {
    document.getElementById('fb-root').hidden = true;
    let firstParam = null;
    let secondParam = null
    this.activeRoute.queryParams.subscribe(params => {
      if (params['date'] && params['nights']) {
        firstParam = params['date'];
        secondParam = params['nights'];
        this.dataService.date = firstParam;
        this.dataService.nights = Number(secondParam);
      }
    });
  }
  detectDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.isDesktop = this.deviceService.isDesktop();
    this.dataService.isMobile = this.isMobile;
    this.dataService.isTablet = this.isTablet;
    this.dataService.isDesktop = this.isDesktop;
  }
}
