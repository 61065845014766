import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerService } from '../services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.css']
})
export class SingleBlogComponent implements OnInit {
  public blog = {
    imageUrl: '',
    contentId: '',
    blogTitle: '',
  };
  private titleOfTheBlog: any;
  constructor(private customerServ: CustomerService,
    private route: Router,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.paramMap.subscribe(params => {
      this.titleOfTheBlog = params.get('title');
    });
  }

  ngOnInit() {
    document.getElementById('fb-root').hidden = false;
    this.onActivate();
    this.titleOfTheBlog = this.replaceAll(this.titleOfTheBlog, '-', ' ');
    this.titleOfTheBlog = this.titleOfTheBlog.trim();
    this.getblog();

  }
  getblog() {
    this.customerServ.getBlogDetails(this.titleOfTheBlog, environment.hostelId).then(
      res => {
        const response = res as any;
        this.blog.imageUrl = response.data.imageUrl;
        this.blog.blogTitle = response.data.blogTitle;
        this.blog.contentId = response.data.contentId;
      }).catch();
  }
  gotoLoginPage() {
    this.route.navigateByUrl('/login');
  }
  onActivate() {
    window.scroll(0, 0);
  }
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
  }
}
