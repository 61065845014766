import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaskModule } from 'ngx-mask';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './services/admin-http.service';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { FacebookModule } from 'ngx-facebook';
@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    SingleBlogComponent,
  ],
  imports: [
    BrowserModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    TabsModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    Ng2SearchPipeModule,
    NgbModule,
    RouterModule.forRoot(routes),
    Angular2PromiseButtonModule
      .forRoot({
        // your custom config goes here
        // spinnerTpl: '<span class="spinner-border"></span>',
        // disable buttons when promise is pending
        disableBtn: true,
        // the class used to indicate a pending promise
        btnLoadingClass: 'is-loading',
        // only disable and show is-loading class for clicked button,
        // even when they share the same promise
        handleCurrentBtnOnly: false,
      }),
    TooltipModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    PaginationModule.forRoot(),
    FacebookModule.forRoot()
  ],
  providers: [DatePipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }, Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
