import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { create } from 'domain';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(public  http: HttpClient) {
  }
  public get(endpoit): Observable<any> {
    return this.http.get(environment.baseUrl + endpoit);
  }
  public post(endpiont, payload): Observable<any> {
    return this.http.post(environment.baseUrl + endpiont, payload);
  }
  public put(endpoit, payload): Observable<any> {
    return this.http.put(environment.baseUrl + endpoit, payload);
  }
  public delete(endpiont): Observable<any> {
    return this.http.delete(environment.baseUrl + endpiont);
  }
}
