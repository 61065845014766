import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  public isLoading = false;
  public loadingPromise: Promise<any>;
  public userName = ''; // user name for binding with username text field
  public userPassword = ''; // user password for binding with field
  error = null;
  response = null;
  // payload for login
  playLoad = {
    userName: '',
    password: ''
  };
  constructor(private adminService: AdminService, private route: Router, private dataServ: DataService) { }

  ngOnInit() {
    document.getElementById('fb-root').hidden = true;
  }
  /**
   * Purpose : Submit login from for admin
   * ref : no external library is used
   * params : empty
   * @ return: [{ "items":[],"error":false,"success":true,"message":"Successfully login"}}]
   */
  onSubmit() {
    this.isLoading = true;
    this.playLoad.userName = this.userName;
    this.playLoad.password = this.userPassword;
    const deviceToken = this.dataServ.getFcmToken();
    this.loadingPromise = this.adminService.logIn(this.playLoad).
      then(res => {
        this.response = <any>res;
        const payload = {
          userId: 'admin',
          token: deviceToken
        };
        this.adminService.registerAdminDevice(payload).then(resp => { }).catch(err => { });
        localStorage.setItem('token', 'barer ' + this.response.token);
        this.isLoading = false;
        this.route.navigateByUrl('/admin/dashboard');
        this.error = null;
      })
      .catch(err => {
        this.error = (err as any).message;
        this.isLoading = false;
      });
  }
  navigateToLandingPage() {
    this.route.navigateByUrl('/');
  }
}
