import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isDesktop = false;
  public isMobile = false;
  public isTablet = false;
  public fcm_token = null;
  public phonenumber = '';
  public date = '';
  public nights = 1;
  public successfullTransaction = false;
  constructor() { }
  setFcmToken(token: any) {
    this.fcm_token = token;
  }
  getFcmToken(): any {
    return this.fcm_token;
  }
  generateCyphorKey (stringData: any) {
    const cyphor = CryptoJS.AES.encrypt(JSON.stringify(stringData), environment.encryptionKey).toString();
    return <any> cyphor;
  }
}
