import { AdminHttpService } from './admin-http.service';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AdminService extends HttpService {
  constructor(public http: HttpClient, public adminHttp: AdminHttpService,
    private toast: ToastrService,
    private route: Router) {
    super(http);
  }
  /**
   * config for the toast message
   */
  toastserviceConfig: object = {
    toastClass: 'ngx-toastr',
    timeOut: 8000,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  };
  /**
   * purpose : function which promise to return some reponse
   * of admin's request for today beds and their details
   * params: today : Query Param
   */
  getData(startDate, endDate, hostelId) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getBedsDetails?startDate=' + startDate + '&hostelId=' + hostelId + '&endDate=' + endDate)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              // reject(response.message);
            }
          },
          err => {
            reject(err);
            // this.toastr.error(err.status.message.details, 'Oopss!');
          }
        );
    });
  }
  /**
  * purpose : function which promise to return some reponse
  * of admin's request for all transactions
  * params: today : Query Param
  */
  getTransactionData() {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getTransection').subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            // reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  getIndividualTransactionData(hostelID) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getTransection?hostelId=' + hostelID).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            // reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  /**
  * purpose : function which promise to Login admin
  * params: playLoad = { userName : '',password : ''}
  */
  logIn(payload) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.post('/admin/login', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllCoupons(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getCouponsDetails?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            // reject(response.message);
          }
        },
        err => {
          reject(err);
          // if(err.status=== 401){
          //   this.route.navigateByUrl('/admin/login');
          // }
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  addNewCoupon(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/addCoupon', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  updateCoupon(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/editCoupon', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  errorHandler(error) {
    // if(error.status === 401){

    // }

  }
  lockbedService(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/bedLock', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  removeCoupon(couponId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.delete('/admin/deleteCoupon?couponId=' + couponId).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  unlocked(bedId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.delete('/admin/bedUnlock?bedId=' + bedId).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  getAllHostels() {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAllHostel').subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  addNewHostel(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/addHostel', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  addNewBed(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/addBeds', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  addNewRoom(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/addRoom', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  getAllRooms(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAllRoom?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            // reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  getRoomPrices(hostelId, type) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getPrice?hostelId=' + hostelId + '&' + 'type=' + type).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  updateRoomRent(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/editPrice', payload).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  getAdminMessage(hostelId, customerId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAdminSms?hostelId=' + hostelId + '&' + 'customerId=' + customerId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllCustomer(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAllCustomer?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllRequestsForChangeRoom(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAllRequestToChangeRoom?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  acceptChangeRoomRequest(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/acceptRequestChangeRoom', payload).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });

  }
  rejectRequest(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/rejectRequestToChangeRoom', payload).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllTransaction(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getTransectionDetails?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  registerAdminDevice(payload) {
    return new Promise((resolve, reject) => {
      this.post('/admin/addDeviceToken', payload).subscribe(
        response => {
          if (response.success === true) {
            resolve(response);
          } else {
            this.toast.warning(
              'Warning',
              response.message,
              this.toastserviceConfig
            );
            reject(false);
          }
        },
        err => {
          // this.toastr.error(err.status.message.details, 'Oopss!');
          this.toast.warning(
            'Warning',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  deleteRegisteredDevice(userId, deviceToken) {
    return new Promise((resolve, reject) => {
      this.adminHttp.delete('/admin/deleteDeviceToken?userId=' + userId + '&' + 'token=' + deviceToken).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllNotifications(hostelId, userId) {

    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getAdminNotification?hostelId=' + hostelId + '&' + 'customerId=' + userId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  updateStatusOfNotification(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/updateNotificationReadStatus', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  updateSingleNofification(payload) {
    return new Promise((resolve, reject) => {
      this.put('/common/updateSingleNotificationReadStatus', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  locakMainDoor(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/mainDoorLock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  unlocakMainDoor(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/mainDoorUnLock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  locakRoomDoor(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/lock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  unlocakRoomDoor(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/unlock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getRules(hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getHostelRules?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  updateRules(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/editHostelRules', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  blockUser(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/blockUser', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  unblockUser(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/unblockUser', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getLockData() {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getRoomLockInfo')
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              // reject(response.message);
            }
          },
          err => {
            reject(err);
            // this.toastr.error(err.status.message.details, 'Oopss!');
          }
        );
    });
  }
  getPassCodeData(lockId, hostelId) {
    return new Promise((resolve, reject) => {
     this.adminHttp.get('/admin/getPassCodes?lockId=' + lockId + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              // reject(response.message);
            }
          },
          err => {
            reject(err);
            // this.toastr.error(err.status.message.details, 'Oopss!');
          }
        );
    });
  }
  updatePasscode(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/editPassCodes', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  newReservationByAdmin(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/admin/adminMakeReservation', payLoad).subscribe(
        response => {
          if (response.success === true) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          this.toast.error(
            '',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  postBlog(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/admin/addBlog', payLoad).subscribe(
        response => {
          if (response.success === true) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(false);
          }
        },
        err => {
          this.toast.error(
            '',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  deleteBlog(blogId, hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.delete('/admin/deleteBlug?blogId=' + blogId + '&hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  updateBlog(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/editBlog', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getBlogDetails(blogId, hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/common/getBlogDetail?blogId=' + blogId + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getBlogs(blogId, limit, hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/common/getBlogs?blogId=' + blogId + '&limit=' + limit + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  uploadBlogImage(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/common/uploadImage', payLoad).subscribe(
        response => {
          if (response.success === true) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(false);
          }
        },
        err => {
          this.toast.error(
            '',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  getLockHistory(lockId, hostelId) {
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getLockHistory?lockId=' + lockId + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getFaildedTransactionData(transMsgId, limit, hostelId) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getFailedTransaction?transMsgId=' + transMsgId + 'limit=' + limit + 'hostelId =' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  addNewPassCode(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/admin/addPassCode', payLoad).subscribe(
        response => {
          if (response.success === true) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(false);
          }
        },
        err => {
          this.toast.error(
            '',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  deletePassCode(payLoad) {
    return new Promise((resolve, reject) => {
      this.adminHttp.post('/admin/deletePassCode', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.message);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  /**
 * purpose : function which promise to return some reponse
 * of admin's request for today beds and their details
 * params: today : Query Param
 */
  getSpecificUserDetail(customerId, hostelId) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getSpecificCustomerInfo?userId=' + customerId + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              reject(response.message);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  cancelReservation(payload) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/cancelReservation', payload)
        .subscribe(
          response => {
            if (response.error === false) {
              this.toast.success(
                '',
                response.message,
                this.toastserviceConfig
              );
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              reject(response.message);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getTransactioDetails(customerId, hostelId) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.adminHttp.get('/admin/getCustomerDeatail?userId=' + customerId + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              reject(response.message);
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  extendReservation(payload) {
    return new Promise((resolve, reject) => {
      this.adminHttp.put('/admin/updateReservation', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
