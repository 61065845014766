import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CustomerHttpService } from './customer-http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends HttpService {
  public customerToken: any = null;
  constructor(
    public http: HttpClient,
    private customerhttpservice: CustomerHttpService,
    private toast: ToastrService
  ) {
    super(http);
  }
  toastserviceConfig: object = {
    toastClass: 'ngx-toastr',
    timeOut: 8000,
    progressBar: true,
    positionClass: 'toast-top-right'
  };
  toastserviceConfigPlaceOrder: object = {
    toastClass: 'ngx-toastr',
    timeOut: 10000,
    progressBar: true,
    positionClass: 'toast-top-right',
    closeButton: true,
    preventDuplicates: true
  };

  /**
   * Purpose : check the availablity of rooms for a specific time period and no of beds
   * params : playload {}
   */
  checkRoomAvailibility(payload) {
    return new Promise((resolve, reject) => {
      this.post('/users/checkAvailability', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error('', response.message, this.toastserviceConfig);
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * Purpose : place the orders
   * params : playload {}
   */
  placeOrder(payload) {
    return new Promise((resolve, reject) => {
      this.post('/users/card', payload).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfigPlaceOrder
            );
            // alert(response.message);
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfigPlaceOrder
            );
            // alert(response.message);
            reject(response);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }

  /**
   * Purpose : check the card number's validity and type
   * params : playload {}
   */
  checkCardNumber(payload) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.post('/users/checkCardType', payload).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response.cardInfo.type);
            // this.toast.error('',response.message,this.toastserviceConfig);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  customerSignIn(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/users/login', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
            // this.toast.error('',response.message,this.toastserviceConfig);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  customerTextedPin(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/users/userVarification', payLoad).subscribe(
        response => {
          if (response.error === false) {
            this.customerToken = response.token;
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
            // this.toast.error('',response.message,this.toastserviceConfig);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
          reject(err);
        }
      );
    });
  }
  getSpecificCustomerData() {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.customerhttpservice.get('/users/getReservationDetails').subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  // --------------------------------------------------------------------------------------//
  cancelReservation(payLoad) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice
        .put('/users/cancelReservation', payLoad)
        .subscribe(
          response => {
            if (response.error === false) {
              this.toast.success(
                '',
                response.message,
                this.toastserviceConfig
              );
              resolve(response.message);
            } else {
              this.toast.error(
                '',
                response.message,
                this.toastserviceConfig
              );
              reject(response.message);
            }
          },
          err => {
            reject(err);
            // this.toastr.error(err.status.message.details, 'Oopss!');
            reject(err);
          }
        );
    });
  }
  updateReservation(payLoad) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice
        .put('/users/updateReservation', payLoad)
        .subscribe(
          response => {
            if (response.error === false) {
              this.toast.success(
                '',
                response.message,
                this.toastserviceConfig
              );
              resolve(response.message);
            } else {
              this.toast.error(
                'Error',
                response.message,
                this.toastserviceConfig
              );
              reject(response.message);
            }
          },
          err => {
            reject(err);
            this.toast.error(
              '',
              err.message,
              this.toastserviceConfig
            );
            reject(err);
          }
        );
    });
  }
  getAllHostels() {
    return new Promise((resolve, reject) => {
      this.get('/users/getAllHostel').subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllRooms(hostelId) {
    return new Promise((resolve, reject) => {
      this.get('/users/getAllRoom?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            // reject(response.message);
          }
        },
        err => {
          reject(err);
          // this.toastr.error(err.status.message.details, 'Oopss!');
        }
      );
    });
  }
  getCustomerMessage(hostelId, customerId) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.get('/users/getCustomerSMS?hostelId=' + hostelId + '&' + 'customerId=' + customerId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  registerUserOnFcm(payload) {
    return new Promise((resolve, reject) => {
      this.post('/users/addDeviceToken', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            this.toast.warning(
              'Warning',
              response.message,
              this.toastserviceConfig
            );
            reject(response);
          }
        },
        err => {
          this.toast.warning(
            'Warning',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
  changeRoom(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.post('/users/requestToChangeRoom', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  updateRoom(payload) {
    return new Promise((resolve, reject) => {
      this.put('/users/updateRequestToChangeRoom', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  locakMainDoor(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/mainDoorLock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  unlocakMainDoor(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/mainDoorUnLock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  locakRoomDoor(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/lock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  unlocakRoomDoor(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/unlock', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  deleteRegisteredDevice(userId, deviceToken) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.delete('/users/deleteDeviceToken?userId=' + userId + '&' + 'token=' + deviceToken).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getAllNotifications(hostelId, userId) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.get('/users/getCustomerNotification?hostelId=' + hostelId + '&' + 'customerId=' + userId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  updateStatusOfNotification(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/updateNotificationReadStatus', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getRules(hostelId) {
    return new Promise((resolve, reject) => {
      this.get('/users/getHostelRules?hostelId=' + hostelId).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  cancelCustomerReservation(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/cancelReservation', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  newReservationServ(payload) {
    return new Promise((resolve, reject) => {
      this.customerhttpservice.put('/users/newReservation', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
          } else {
            reject(response);
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  getBlogs(blogId, limit, hostelId) {
    return new Promise((resolve, reject) => {
      this.get('/common/getBlogs?blogId=' + blogId + '&limit=' + limit + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              // this.toast.error(
              //   'Error',

              //   response.message,
              //   this.toastserviceConfig
              // );
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getBlogDetails(blogTitle, hostelId) {
    return new Promise((resolve, reject) => {
      this.get('/common/getBlogDetail?blogTitle=' + blogTitle + '&hostelId=' + hostelId)
        .subscribe(
          response => {
            if (response.error === false) {
              resolve(response);
            } else {
              this.toast.error(
                'Error',
                response.message,
                this.toastserviceConfig
              );
            }
          },
          err => {
            reject(err);
          }
        );
    });
  }
  updateSingleNofification(payload) {
    return new Promise((resolve, reject) => {
      this.put('/common/updateSingleNotificationReadStatus', payload).subscribe(
        response => {
          if (response.error === false) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }
  uploadBlogImage(payLoad) {
    return new Promise((resolve, reject) => {
      this.post('/common/uploadImage', payLoad).subscribe(
        response => {
          if (response.success === true) {
            this.toast.success(
              '',
              response.message,
              this.toastserviceConfig
            );
            resolve(response);
          } else {
            this.toast.error(
              '',
              response.message,
              this.toastserviceConfig
            );
            reject(false);
          }
        },
        err => {
          this.toast.error(
            '',
            err.message,
            this.toastserviceConfig
          );
          reject(err);
        }
      );
    });
  }
}
