
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminModule } from './admin/admin.module';
import { CloudbunksModule } from './cloudbunks/cloudbunks.module';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { BlogsComponent } from './admin/blogs/blogs.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: '',
    loadChildren: './cloudbunks/cloudbunks.module#CloudbunksModule'
  },
  {
    path: 'adminlogin',
    component: AdminLoginComponent
  },
  {
    path: 'blog/:title',
    component: SingleBlogComponent
  },
  {
    path: '**',
    loadChildren: './cloudbunks/cloudbunks.module#CloudbunksModule'

  }
];
export class AppRoutingModule { }
