import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { create } from 'domain';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => { },
        (Error: HttpErrorResponse) => {
          if (Error instanceof HttpErrorResponse && Error.status === 401) {
            this.router.navigateByUrl('/adminlogin');
          }
          // if (Error instanceof HttpErrorResponse && Error.status === 404) {
          //   this.router.navigateByUrl('/');
          // }
        })
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class AdminHttpService {
  constructor(public http: HttpClient) {
  }
  public get(endpoit): Observable<any> {
    const options = {
      headers: this.getHeader()
    };
    return this.http.get(environment.baseUrl + endpoit, options);
  }
  public post(endpiont, payload): Observable<any> {
    const options = {
      headers: this.getHeader()
    };
    return this.http.post(environment.baseUrl + endpiont, payload, options);
  }
  public put(endpoit, payload): Observable<any> {
    const options = {
      headers: this.getHeader()
    };
    return this.http.put(environment.baseUrl + endpoit, payload, options);
  }
  public delete(endpiont): Observable<any> {
    const options = {
      headers: this.getHeader()
    };
    return this.http.delete(environment.baseUrl + endpiont, options);
  }
  public getHeader() {
    const header: HttpHeaders = new HttpHeaders({ 'authorization': localStorage.getItem('token') });
    return header;
  }
}
